import * as React from "react";
import { Container } from "./Container";
import { Listbox, Disclosure } from "@headlessui/react";
import clsx from "clsx";
import { GitHubIcon } from "./Footer";
import { TryItOut } from "./TryItOut";
import { useDebounce } from "@uidotdev/usehooks";

const CaretDown = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      strokeWidth={1.5}
      stroke="currentColor"
      className="w-4 h-4"
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="m19.5 8.25-7.5 7.5-7.5-7.5"
      />
    </svg>
  );
};

type SearchResult = {
  score: number;
  symbol: string;
  language: string;
  file: string;
  documentation: string;
  url: string;
  parsedName: {
    moduleName: string;
    language: string;
    type: string;
    name: string;
    scipIndexer: string;
    packageName: string;
  };
};

type Repo = {
  repo: string;
  sampleQueries: string[];
  language: string;
  url: string;
  githubInfo: {
    owner: {
      avatar_url: string;
    };
  };
};

const repositories: Repo[] = await fetch(
  "https://app.codemuse.app/playground/repositories"
).then((response) => response.json());

/*
const repositories: Repo[] = await Promise.all(
  rawRepositories.map(async (repo) => {
    const apiQueryUrl = repo.url.replace("github.com", "api.github.com/repos");

    const githubInfo = await fetch(apiQueryUrl).then((response) =>
      response.json()
    );

    return { ...repo, githubInfo };
  })
); */

const query = async (
  repository: string,
  search: string
): Promise<SearchResult[]> => {
  const url = new URL("https://app.codemuse.app/playground/query");
  url.searchParams.append("repository", repository);
  url.searchParams.append("query", search);

  const results = await fetch(url.toString(), {}).then((response) =>
    response.json()
  );

  return results.results;
};

const firstQuery = repositories[0].sampleQueries[0];

const sampleResponse = await query(repositories[0].repo, firstQuery);

export const RepositoriesSelector = ({
  onChange,
}: {
  onChange?: (repo: Repo) => void;
}) => {
  const [selectedRepository, setSelectedRepository] = React.useState(
    repositories[0]
  );

  React.useEffect(() => {
    onChange?.(selectedRepository);
  }, [selectedRepository]);

  return (
    <div className="text-sm text-white/60">
      <Listbox
        as="div"
        className="relative inline-block"
        value={selectedRepository}
        onChange={setSelectedRepository}
      >
        <Listbox.Button className="flex items-center hover:bg-slate-700 ui-open:bg-slate-700 px-2 py-1 rounded-md gap-1">
          in
          <span className="underline underline-offset-2">
            {selectedRepository.repo}
          </span>
          <CaretDown />
        </Listbox.Button>
        <Listbox.Options className="absolute z-50 p-1 border backdrop-blur-md bg-slate-800 mt-1 rounded-md border-slate-700 min-w-full shadow-xl ui-active:bg-red-400 ">
          {repositories.map((repository) => (
            <Listbox.Option
              className="px-2 py-2 whitespace-nowrap hover:bg-slate-700 rounded-md cursor-pointer"
              key={repository.repo}
              value={repository}
            >
              {repository.repo}
              <span className="text-xs inline-block px-1 py-0.5 bg-slate-900 ml-2 rounded-sm uppercase">
                {repository.language === "python" ? "py" : "ts"}
              </span>
            </Listbox.Option>
          ))}
        </Listbox.Options>
      </Listbox>
    </div>
  );
};

export const SearchBar = ({
  onChange,
  defaultValue,
  innerRef,
}: {
  onChange?: (value: string) => void;
  defaultValue?: string;
  innerRef?: React.Ref<HTMLInputElement>;
}) => {
  return (
    <div className="relative flex items-center px-4">
      <div>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth={1.5}
          stroke="currentColor"
          className="w-6 h-6"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="m21 21-5.197-5.197m0 0A7.5 7.5 0 1 0 5.196 5.196a7.5 7.5 0 0 0 10.607 10.607Z"
          />
        </svg>
      </div>
      <input
        ref={innerRef}
        onChange={(e) => onChange?.(e.target.value)}
        type="text"
        className="px-4 py-2 focus:outline-none w-full box-border text-white bg-transparent"
        placeholder="Search for anything..."
        defaultValue={defaultValue}
      />
    </div>
  );
};

export const SearchExamples = ({
  suggestions,
  onChange,
}: {
  suggestions: string[];
  onChange: (value: string) => void;
}) => {
  return (
    <div>
      <div className="select-none text-sm text-slate-400 p-1 flex flex-col gap-1 items-start">
        <div className="px-2 pb-1">Or try one of...</div>
        {suggestions.map((suggestion) => (
          <div
            key={suggestion}
            onClick={() => onChange(suggestion)}
            className="bg-slate-700 bg-opacity-70 rounded-md px-3 mx-1 py-1 cursor-pointer hover:bg-slate-700 hover:text-white"
          >
            {suggestion}
          </div>
        ))}
      </div>
    </div>
  );
};

const getResultColors = (score: number) => {
  if (score > 0.4) {
    return {
      backgroundColor: "#047857",
      color: "#d1fae5",
    };
  } else if (score > 0.2) {
    return {
      backgroundColor: "#d97706",
      color: "#fef3c7",
    };
  }

  return {
    backgroundColor: "#b91c1c",
    color: "#fee2e2",
  };
};

export const SearchResults = ({
  isLoading,
  searchResults,
}: {
  isLoading: boolean;
  searchResults: SearchResult[];
}) => {
  return (
    <div className={clsx(isLoading ? "opacity-40" : "opacity-100")}>
      {searchResults
        .filter((result) => result.score > 0.15)
        .map((result, key) => {
          return (
            <Disclosure
              defaultOpen={key === 0}
              as="div"
              key={result.symbol}
              className="border-t border-slate-700 cursor-pointer hover:bg-slate-900"
            >
              <Disclosure.Button as="div" className="p-3">
                <div className="flex items-start select-none">
                  <div className={clsx("h-5 pr-2 flex items-end")}>
                    <span className="ui-open:rotate-180 rotate-0">
                      <CaretDown />
                    </span>
                  </div>
                  <span className="flex-grow">{result.parsedName.name}</span>
                  <div
                    className="text-xs leading-3 px-1 py-0.5 text-white/60 rounded-sm bg-slate-700"
                    style={getResultColors(result.score)}
                  >
                    {result.score.toFixed(2)}
                  </div>
                </div>
                <Disclosure.Panel>
                  <div className="py-2 text-white/60 text-sm">
                    {result.documentation}
                  </div>
                  <div className="py-1 -mx-1">
                    <a
                      href={result.url}
                      target="_blank"
                      className="text-indigo-400 text-xs inline-flex items-center gap-1 px-1 hover:bg-slate-800 py-1 rounded-sm"
                      // On click, prevent the event from bubbling up to the parent
                      onClick={(e) => e.stopPropagation()}
                    >
                      View on GitHub
                      <GitHubIcon />
                    </a>
                  </div>
                </Disclosure.Panel>
                <div className="select-none">
                  <small className="text-white/40">{result.file}</small>
                </div>
              </Disclosure.Button>
            </Disclosure>
          );
        })}
    </div>
  );
};

const PlaygroundHeader = ({
  children,
  ...props
}: { children: React.ReactNode } & React.ComponentPropsWithoutRef<"div">) => {
  return (
    <div
      {...props}
      className={clsx("text-white/60 px-4 py-2 text-sm", props.className)}
    >
      {children}
    </div>
  );
};

const Step = ({ children }: { children: string }) => {
  return (
    <span className="w-6 h-6 mr-2 text-sm font-bold -ml-2 inline-flex items-center justify-center rounded-full bg-indigo-700 text-white">
      {children}
    </span>
  );
};

export const Playground = () => {
  const [selectedRepo, setSelectedRepo] = React.useState(repositories[0]);
  const [currentRepo, setCurrentRepo] = React.useState(repositories[0]);
  const [rawQuery, setRawQuery] = React.useState(firstQuery);
  const debouncedQuery = useDebounce(rawQuery, 500);
  const [currentQuery, setCurrentQuery] = React.useState(firstQuery);

  const [searchResults, setSearchResults] = React.useState(sampleResponse);
  const [isLoading, setIsLoading] = React.useState(false);

  const searchInputRef = React.useRef<HTMLInputElement>(null);

  React.useEffect(() => {
    if (!selectedRepo) return;
    if (!debouncedQuery.length) return;
    if (currentQuery === debouncedQuery && currentRepo === selectedRepo) return;

    setIsLoading(true);

    // @ts-expect-error
    if (posthog) {
      // @ts-expect-error
      posthog.capture("playgroundQuery", {
        query: debouncedQuery,
        repository: selectedRepo.repo,
      });
    }

    query(selectedRepo.repo, debouncedQuery)
      .then((results) => {
        setCurrentRepo(selectedRepo);
        setCurrentQuery(debouncedQuery);
        setSearchResults(results);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [selectedRepo, currentRepo, debouncedQuery, currentQuery]);

  const setExample = (example: string) => {
    setRawQuery(example);

    console.log(searchInputRef.current);

    if (!searchInputRef.current) return;

    searchInputRef.current.value = example;
    searchInputRef.current.focus();

    console.log("done setting");
  };

  const setRepository = (repo: Repo) => {
    setSelectedRepo(repo);
    setExample(repo.sampleQueries[0]);
  };

  return (
    <div className="relative py-64 -my-48 text-left">
      <Container>
        <div className="mx-[calc(-0.5rem-2px)] relative z-10 p-1 border border-slate-600/50 bg-slate-800/10 rounded-[1.5rem]">
          <div className="p-1 border border-slate-600/75 bg-slate-800/10 rounded-[1.25rem]">
            <div className="border-slate-600 bg-slate-800/30 rounded-2xl border  grid md:grid-cols-2 md:h-[60vh] backdrop-blur-sm shadow-2xl shadow-indigo-400/20">
              <div>
                <div className="bg-slate-900 relative z-10 focus-within:shadow-xl shadow-indigo-500/20  border m-1 border-indigo-500 rounded-xl focus-within:ring-1 ring-indigo-500">
                  <div className="text-white h-0 relative">
                    <TryItOut
                      className="h-16 absolute bottom-5 left-1/2 -translate-x-1/2"
                      alt="Try CodeMuse in the playground now!"
                    />
                  </div>
                  <label>
                    <PlaygroundHeader>
                      <Step>1</Step>Search
                    </PlaygroundHeader>
                    {/** TODO: add ref to clear the input or set its contents on click of suggestion */}
                    <SearchBar
                      innerRef={searchInputRef}
                      defaultValue={rawQuery}
                      onChange={setRawQuery}
                    />
                  </label>
                  <div className="px-4">
                    <div
                      className={clsx(
                        isLoading ? "opacity-100" : "opacity-0",
                        "transition-all duration-150 overflow-hidden rounded-full relative h-1 bg-indigo-500/20"
                      )}
                    >
                      <style>
                        {`
                        @keyframes loading {
                          0% {
                            left: -8rem;
                          }
                          100% {
                            left: 100%;
                          }
                        }

                        .loader-span {
                          animation: loading 1.3s infinite linear;
                        }
                      `}
                      </style>
                      <div className="h-full bg-gradient-to-r from-indigo-500/0 to-indigo-500 w-32 loader-span absolute"></div>
                    </div>
                  </div>
                  <div className="p-2 pt-1">
                    <RepositoriesSelector onChange={setRepository} />
                  </div>
                </div>
                <div className="py-2">
                  <SearchExamples
                    onChange={setExample}
                    suggestions={selectedRepo.sampleQueries || []}
                  />
                </div>
              </div>
              <div className="overflow-y-scroll overflow-x-hidden md:border-l border-slate-700 border-t md:border-t-0 max-h-[60vh]">
                <PlaygroundHeader>
                  <Step>2</Step>Results
                </PlaygroundHeader>
                <SearchResults
                  isLoading={isLoading}
                  searchResults={searchResults}
                />
              </div>
            </div>
          </div>
        </div>
      </Container>
      <svg
        width="100%"
        height="100%"
        xmlns="http://www.w3.org/2000/svg"
        className="absolute top-0 left-0 pointer-events-none opacity-30 -z-10"
        style={{
          WebkitMaskImage:
            "linear-gradient(to bottom, transparent 0%, black 25%, black 75%, transparent 100%)",
          maskImage:
            "linear-gradient(to bottom, transparent 0%, black 25%, black 75%, transparent 100%)",
        }}
      >
        <defs>
          <pattern
            id="smallGrid"
            width="8"
            height="8"
            patternUnits="userSpaceOnUse"
          >
            <path
              d="M 8 0 L 0 0 0 8"
              fill="none"
              stroke="gray"
              strokeWidth="0.5"
            />
          </pattern>
          <pattern
            id="grid"
            width="80"
            height="80"
            patternUnits="userSpaceOnUse"
          >
            <rect width="80" height="80" fill="url(#smallGrid)" />
            <path
              d="M 80 0 L 0 0 0 80"
              fill="none"
              stroke="gray"
              strokeWidth="1"
            />
          </pattern>
        </defs>
        <rect width="100%" height="100%" fill="url(#grid)" />
      </svg>
    </div>
  );
};
