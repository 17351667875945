import type { ComponentPropsWithoutRef } from "react";
import { WordMark } from "./Brand";
import { HighlightedButton } from "./Button";
import { Container } from "./Container";

const DiscordIcon = () => {
  return (
    <svg
      className="w-4 h-4"
      aria-hidden="true"
      xmlns="http://www.w3.org/2000/svg"
      fill="currentColor"
      viewBox="0 0 21 16"
    >
      <path d="M16.942 1.556a16.3 16.3 0 0 0-4.126-1.3 12.04 12.04 0 0 0-.529 1.1 15.175 15.175 0 0 0-4.573 0 11.585 11.585 0 0 0-.535-1.1 16.274 16.274 0 0 0-4.129 1.3A17.392 17.392 0 0 0 .182 13.218a15.785 15.785 0 0 0 4.963 2.521c.41-.564.773-1.16 1.084-1.785a10.63 10.63 0 0 1-1.706-.83c.143-.106.283-.217.418-.33a11.664 11.664 0 0 0 10.118 0c.137.113.277.224.418.33-.544.328-1.116.606-1.71.832a12.52 12.52 0 0 0 1.084 1.785 16.46 16.46 0 0 0 5.064-2.595 17.286 17.286 0 0 0-2.973-11.59ZM6.678 10.813a1.941 1.941 0 0 1-1.8-2.045 1.93 1.93 0 0 1 1.8-2.047 1.919 1.919 0 0 1 1.8 2.047 1.93 1.93 0 0 1-1.8 2.045Zm6.644 0a1.94 1.94 0 0 1-1.8-2.045 1.93 1.93 0 0 1 1.8-2.047 1.918 1.918 0 0 1 1.8 2.047 1.93 1.93 0 0 1-1.8 2.045Z"></path>
    </svg>
  );
};

const LinkedInIcon = () => {
  return (
    <svg
      className="w-4 h-4"
      aria-hidden="true"
      viewBox="0 0 256 256"
      xmlns="http://www.w3.org/2000/svg"
      fill="currentColor"
    >
      <path d="M218.123122,218.127392 L180.191928,218.127392 L180.191928,158.724263 C180.191928,144.559023 179.939053,126.323993 160.463756,126.323993 C140.707926,126.323993 137.685284,141.757585 137.685284,157.692986 L137.685284,218.123441 L99.7540894,218.123441 L99.7540894,95.9665207 L136.168036,95.9665207 L136.168036,112.660562 L136.677736,112.660562 C144.102746,99.9650027 157.908637,92.3824528 172.605689,92.9280076 C211.050535,92.9280076 218.138927,118.216023 218.138927,151.114151 L218.123122,218.127392 Z M56.9550587,79.2685282 C44.7981969,79.2707099 34.9413443,69.4171797 34.9391618,57.260052 C34.93698,45.1029244 44.7902948,35.2458562 56.9471566,35.2436736 C69.1040185,35.2414916 78.9608713,45.0950217 78.963054,57.2521493 C78.9641017,63.090208 76.6459976,68.6895714 72.5186979,72.8184433 C68.3913982,76.9473153 62.7929898,79.26748 56.9550587,79.2685282 M75.9206558,218.127392 L37.94995,218.127392 L37.94995,95.9665207 L75.9206558,95.9665207 L75.9206558,218.127392 Z M237.033403,0.0182577091 L18.8895249,0.0182577091 C8.57959469,-0.0980923971 0.124827038,8.16056231 -0.001,18.4706066 L-0.001,237.524091 C0.120519052,247.839103 8.57460631,256.105934 18.8895249,255.9977 L237.033403,255.9977 C247.368728,256.125818 255.855922,247.859464 255.999,237.524091 L255.999,18.4548016 C255.851624,8.12438979 247.363742,-0.133792868 237.033403,0.000790807055"></path>
    </svg>
  );
};

const XIcon = () => {
  return (
    <svg
      className="w-4 h-4"
      aria-hidden="true"
      xmlns="http://www.w3.org/2000/svg"
      width="1200"
      height="1227"
      viewBox="0 0 1200 1227"
    >
      <g clipPath="url(#clip0_1_2)">
        <path
          d="M714.163 519.284L1160.89 0H1055.03L667.137 450.887L357.328 0H0L468.492 681.821L0 1226.37H105.866L515.491 750.218L842.672 1226.37H1200L714.137 519.284H714.163ZM569.165 687.828L521.697 619.934L144.011 79.6944H306.615L611.412 515.685L658.88 583.579L1055.08 1150.3H892.476L569.165 687.854V687.828Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_1_2">
          <rect width="1200" height="1227" fill="currentColor" />
        </clipPath>
      </defs>
    </svg>
  );
};

export const GitHubIcon = () => {
  return (
    <svg
      className="w-4 h-4 fill-current"
      aria-hidden="true"
      xmlns="http://www.w3.org/2000/svg"
      fill="currentColor"
      viewBox="0 0 20 20"
    >
      <path
        fillRule="evenodd"
        d="M10 .333A9.911 9.911 0 0 0 6.866 19.65c.5.092.678-.215.678-.477 0-.237-.01-1.017-.014-1.845-2.757.6-3.338-1.169-3.338-1.169a2.627 2.627 0 0 0-1.1-1.451c-.9-.615.07-.6.07-.6a2.084 2.084 0 0 1 1.518 1.021 2.11 2.11 0 0 0 2.884.823c.044-.503.268-.973.63-1.325-2.2-.25-4.516-1.1-4.516-4.9A3.832 3.832 0 0 1 4.7 7.068a3.56 3.56 0 0 1 .095-2.623s.832-.266 2.726 1.016a9.409 9.409 0 0 1 4.962 0c1.89-1.282 2.717-1.016 2.717-1.016.366.83.402 1.768.1 2.623a3.827 3.827 0 0 1 1.02 2.659c0 3.807-2.319 4.644-4.525 4.889a2.366 2.366 0 0 1 .673 1.834c0 1.326-.012 2.394-.012 2.72 0 .263.18.572.681.475A9.911 9.911 0 0 0 10 .333Z"
        clipRule="evenodd"
      />
    </svg>
  );
};

const FooterLink = ({
  icon,
  href,
  children,
  ...props
}: ComponentPropsWithoutRef<"a"> & {
  icon: React.ReactNode;
  href: string;
  children: React.ReactNode;
}) => {
  return (
    <a
      className="py-1 flex items-center space-x-2 text-slate-500 hover:text-slate-400 text-sm"
      href={href}
      {...props}
    >
      {icon && <span className="inline-block pr-2">{icon}</span>}
      {children}
    </a>
  );
};

export const Footer = () => (
  <footer className="text-slate-500">
    <Container>
      <div className="flex justify-between border-t border-t-slate-700 flex-wrap py-4 md:pt-12 md:pb-8">
        <div className="py-4">
          <WordMark className="fill-white h-6" />
          <p className="py-8 max-w-sm">
            CodeMuse is an AI powered code search and documentation engine that
            helps you find the code you need, when you need it.
          </p>
          <HighlightedButton as="a" href="/get-involved">
            Get the fun of coding back
          </HighlightedButton>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-2 py-4">
          <div>
            <h3 className="text-sm text-white/70 py-2">Resources</h3>
            <FooterLink
              href="https://codemuse.notion.site/a09cd839084048b0bf49dcd98540d01b?v=3cbf6b9c75fe431aa54927ca0ee7b584"
              icon={null}
              target="_blank"
            >
              Documentation
            </FooterLink>
            <FooterLink href="/pricing" icon={null}>
              Pricing
            </FooterLink>
            <FooterLink
              href="https://codemuse.betteruptime.com/"
              icon={null}
              target="_blank"
            >
              Status
            </FooterLink>
          </div>
          <div>
            <h3>Our products</h3>
            <a href="https://onedoclabs.com/" target="_blank">
              Onedoc - Documents for developers
            </a>
            <h3 className="text-sm text-white/70 py-2">Community</h3>
            <FooterLink href={"/get-involved"} icon={null}>
              Newsletter
            </FooterLink>
            <FooterLink
              href={"https://discord.com/invite/uRJE6e2rgr"}
              icon={<DiscordIcon />}
              target="_blank"
            >
              Discord Community<sup>Very active!</sup>
            </FooterLink>
            <FooterLink
              href={"https://twitter.com/CodeMuseApp"}
              icon={<XIcon />}
              target="_blank"
            >
              Twitter
            </FooterLink>
            <FooterLink
              href={"https://www.linkedin.com/company/101338637"}
              icon={<LinkedInIcon />}
              target="_blank"
            >
              LinkedIn
            </FooterLink>
            <FooterLink
              href={"https://github.com/codemuse-app"}
              icon={<GitHubIcon />}
              target="_blank"
            >
              GitHub
            </FooterLink>
          </div>
        </div>
      </div>
      <div className="border-t border-t-slate-700 py-4 md:py-6 text-sm flex items-center">
        <iframe
          src="https://codemuse.betteruptime.com/badge?theme=dark"
          width="250"
          height="30"
        ></iframe>
        <div className="flex-grow"></div>
        <div>
          Built by{" "}
          <a
            className="underline underline-offset-2"
            href="https://www.linkedin.com/in/augustelefevre/"
          >
            Auguste
          </a>
          ,{" "}
          <a
            className="underline underline-offset-2"
            href="https://www.linkedin.com/in/pierre-dorge-3372bb16a/"
          >
            Pierre
          </a>{" "}
          and{" "}
          <a
            className="underline underline-offset-2"
            href="https://www.linkedin.com/in/titouan-launay/"
          >
            Titouan
          </a>{" "}
          with CodeMuse, 2023.
        </div>
      </div>
    </Container>
  </footer>
);
